import ContactDemo from "../components/contactDemo"
// Import Reveal Effects
import Fade from "react-reveal/Fade"
// Import Components
import Layout from "../components/layout"
import Partners from "../components/partners"
import React from "react"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials"

const Demo = () => {
  return (
    <Layout>
      <SEO title="Request a demo - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Request a demo</h1>
          <p className="lead text-light-grey no-top">
            Rooam’s contactless payment solution integrates into your POS system
            without the need for additional hardware.
          </p>
        </div>
      </div>

      <div className="section no-top-bottom">
        <Fade bottom distance={"0.2em"} duration={400}>
          <ContactDemo formName="request-demo" />
        </Fade>
      </div>

      <div className="section">
        <Partners />
      </div>

      <div className="section no-top">
        <Testimonials />
      </div>
    </Layout>
  )
}

export default Demo

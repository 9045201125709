import React, { Component, createRef } from "react"

import InputMask from "react-input-mask"
import emailjs from "@emailjs/browser"
import { navigate } from "gatsby"

class ContactDemo extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      business: "",
      email: "",
      number: "",
      pos: "",
      location: "",
      message: null,
      error: false,
      loading: false,
    }
    this.form = createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange = evt => {
    this.setState({ name: evt.target.value })
  }
  handleBusinessChange = evt => {
    this.setState({ business: evt.target.value })
  }
  handleEmailChange = evt => {
    this.setState({ email: evt.target.value })
  }
  handleNumberChange = evt => {
    this.setState({ number: evt.target.value })
  }
  handlePOSChange = evt => {
    this.setState({ pos: evt.target.value })
  }
  handleLocationChange = evt => {
    this.setState({ location: evt.target.value })
  }

  canBeSubmitted() {
    const { name, business, email, number, pos, location } = this.state

    const posField =
      this.props.formName === "request-demo-toast" ? true : pos.length > 0

    return (
      name.length > 0 &&
      business.length > 0 &&
      email.length > 0 &&
      number.length > 0 &&
      posField &&
      location.length > 0
    )
  }

  handleSubmit(e) {
    this.setState({ error: false, loading: true })
    e.preventDefault()

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        this.form.current,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY
      )
      .then(
        result => {
          if (result.text === "OK") {
            navigate("/request-sent/")
          }
        },
        error => {
          console.log(error.text)

          this.setState({
            error: true,
            message: "An error occurred, please try again.",
          })
        }
      )

    this.setState({ loading: false })
  }

  render() {
    const isEnabled = this.canBeSubmitted()
    const isLoading = this.setState.loading
    const isStaging = process.env.NODE_ENV === "development"

    return (
      <div className="section less-top no-bottom">
        <div className="container medium columns">
          <form
            name="request-demo"
            ref={this.form}
            onSubmit={this.handleSubmit}
          >
            <input
              type="hidden"
              name="form-subject"
              value={`${isStaging ? "Staging: " : ""} Rooam Web | ${
                this.props.formName
              } form: `}
            />
            <div className="row clearfix text-center">
              <div className="column break-full">
                <div className="padding">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    id="contact-name"
                    name="contact-name"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                  />
                </div>
              </div>
              <div className="column break-full">
                <div className="padding">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business or Event Name"
                    id="contact-business"
                    name="contact-business"
                    value={this.state.business}
                    onChange={this.handleBusinessChange}
                  />
                </div>
              </div>
              <div className="column break-full">
                <div className="padding">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    id="contact-email"
                    name="contact-email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                  />
                </div>
              </div>
              <div className="column break-full">
                <div className="padding">
                  <InputMask
                    type="tel"
                    className="form-control"
                    placeholder="Contact Number"
                    mask="(999) 999-9999"
                    id="contact-number"
                    name="contact-number"
                    value={this.state.number}
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
              {this.props.formName !== "request-demo-toast" && (
                <div className="column break-full">
                  <div className="padding">
                    <input
                      type="text"
                      className="form-control no-bottom-margin"
                      placeholder="POS System"
                      id="contact-pos"
                      name="contact-pos"
                      value={this.state.pos}
                      onChange={this.handlePOSChange}
                    />
                  </div>
                </div>
              )}
              <div className="column break-full">
                <div className="padding">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City or State"
                    id="contact-location"
                    name="contact-location"
                    value={this.state.location}
                    onChange={this.handleLocationChange}
                  />
                </div>
              </div>
              <div className="column full">
                <button
                  disabled={!isEnabled || isLoading}
                  type="submit"
                  className="btn middle light no-bottom-margin"
                >
                  {isLoading ? "Sending..." : "Request Demo"}
                </button>
              </div>
            </div>
          </form>
          {this.state.message && <p>{this.state.message}</p>}
        </div>
      </div>
    )
  }
}

export default ContactDemo

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Partners() {

  const data = useStaticQuery(graphql`
    query {
      tao_group: file(relativePath: { eq: "partners/tao-group.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      kimpton: file(relativePath: { eq: "partners/kimpton-hotel.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      house_of_blues: file(relativePath: { eq: "partners/house-of-blues.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      think_food_group: file(relativePath: { eq: "partners/think-food-group.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      live_nation: file(relativePath: { eq: "partners/live-nation.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      the_original_pancake_house: file(relativePath: { eq: "partners/the-original-pancake-house.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      imp: file(relativePath: { eq: "partners/imp.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      sacramento_kings: file(relativePath: { eq: "partners/sacramento-kings.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="section no-top-bottom">
      <div className="container text-center">
        <p className="text-grey font-weight-600 no-top">Trusted by 1,000+ locations including:</p>
      </div>
      <div className="container">
        <div className="row clearfix">
          <div className="column one-four break-half">
            <Img
              fluid={data.live_nation.childImageSharp.fluid}
              alt="Live Nation Entertainment"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.sacramento_kings.childImageSharp.fluid}
              alt="Sacramento Kings"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.kimpton.childImageSharp.fluid}
              alt="Kimpton Hotel & Restaurant"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.tao_group.childImageSharp.fluid}
              alt="TAO Group"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.think_food_group.childImageSharp.fluid}
              alt="Think Food Group (TFG)"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.the_original_pancake_house.childImageSharp.fluid}
              alt="The Original Pancake House"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.house_of_blues.childImageSharp.fluid}
              alt="House of Blues"
              loading="eager"
            />
          </div>
          <div className="column one-four break-half">
            <Img
              fluid={data.imp.childImageSharp.fluid}
              alt="I.M.P. Group"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </div>
  )
}


import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Testimonials() {

  const data = useStaticQuery(graphql`
    query {
      imp: file(relativePath: { eq: "locations/imp.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      southpoint: file(relativePath: { eq: "locations/south-point.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      arlingtonrooftop: file(relativePath: { eq: "locations/arlington-rooftop.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      SACKings: file(relativePath: { eq: "locations/sac-kings.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      nuandroids: file(relativePath: { eq: "locations/nu-androids.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="section no-top-bottom">
      <div className="container columns">
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <p className="lead text-white font-weight-600 no-top-bottom">
                    “Rooam quickly became a preferred payment method at all of our venues. Faster service and hassle-free payments created a quicker and better experience for our guests. Credit cards and cash now feel primitive.”
                  </p>
                  <div className="author">
                    <div className="profile background-dark">
                      <Img
                        fluid={data.imp.childImageSharp.fluid}
                        alt="IMP logo"
                        loading="eager"
                      />
                    </div>
                    <p className="text-white no-top-bottom font-weight-500">Donna Westmoreland</p>
                    <p className="small text-grey no-top-bottom">COO &#64; I.M.P.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <p className="lead text-white font-weight-600 no-top-bottom">
                    “I’m thrilled to overhear Rooam users say the technology is exciting and makes their visit more enjoyable. My bartenders also love the big increase in tip size!”
                  </p>
                  <div className="author">
                    <div className="profile background-dark">
                      <Img
                        fluid={data.southpoint.childImageSharp.fluid}
                        alt="South Point logo"
                        loading="eager"
                      />
                    </div>
                    <p className="text-white no-top-bottom font-weight-500">Vincent Martinez</p>
                    <p className="small text-grey no-top-bottom">Partner @ South Point Baltimore</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <p className="lead text-white font-weight-600 no-top-bottom">
                    “Our collaboration with Rooam is an exciting opportunity to extend our shared focus of disrupting the sports and entertainment industry.”
                  </p>
                  <div className="author">
                    <div className="profile background-dark">
                      <Img
                        fluid={data.SACKings.childImageSharp.fluid}
                        alt="Sacramento Kings logo"
                        loading="eager"
                      />
                    </div>
                    <p className="text-white no-top-bottom font-weight-500">Eric King</p>
                    <p className="small text-grey no-top-bottom">VP of Technology @ Sacramento Kings</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <p className="lead text-white font-weight-600 no-top-bottom">
                    “After adding Rooam to my last event, I can’t imagine another one without it.”
                  </p>
                  <div className="author">
                    <div className="profile background-dark">
                      <Img
                        fluid={data.nuandroids.childImageSharp.fluid}
                        alt="Nu Androids logo"
                        loading="eager"
                      />
                    </div>
                    <p className="text-white no-top-bottom font-weight-500">Nayef Issa</p>
                    <p className="small text-grey no-top-bottom">CEO @ N&uuml; Androids</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <p className="lead text-white font-weight-600 no-top-bottom">
                    “Rooam is a no brainer! Not only for the customer, but for the business owner too. I use it in both scenarios and absolutely love it.”
                  </p>
                  <div className="author">
                    <div className="profile background-dark">
                      <Img
                        fluid={data.arlingtonrooftop.childImageSharp.fluid}
                        alt="Arlington Rooftop Bar & Grill logo"
                        loading="eager"
                      />
                    </div>
                    <p className="text-white no-top-bottom font-weight-500">Johnny Graham</p>
                    <p className="small text-grey no-top-bottom">GM @ Arlington Rooftop Bar & Grill</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

